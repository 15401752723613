"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    getErrorApi(error) {
        if (error.message)
            return error.message;
        return error;
    },
    replaceAll(str, de, para) {
        let aux = para;
        let result = str;
        if (str) {
            if (!para)
                aux = '';
            let pos = result.indexOf(de);
            while (pos > -1) {
                result = result.replace(de, aux);
                pos = result.indexOf(de);
            }
        }
        return result;
    },
    decodeHtmlEntities(htmlString) {
        const htmlEntitiesMap = {
            '&nbsp;': ' ',
            '&ensp;': ' ',
            '&emsp;': ' ',
            '&amp;': '&',
            '&lt;': '<',
            '&gt;': '>',
            '&copy;': '©',
            '&reg;': '®',
            '&trade;': '™',
            '&quot;': '"',
            '&#39;': "'",
            '&cent;': '¢',
            '&pound;': '£',
            '&euro;': '€',
            '&yen;': '¥',
            '&permil;': '‰',
            '&plusmn;': '±',
            '&divide;': '÷',
            '&times;': 'x',
            '&minus;': '-',
            '&sup2;': '²',
            '&sup3;': '³',
            '&frac12;': '½',
            '&frac14;': '¼',
            '&frac34;': '¾',
            '&larr;': '←',
            '&rarr;': '→',
            '&uarr;': '↑',
            '&darr;': '↓',
            '&harr;': '↔',
            '&sect;': '§',
            '&dagger;': '†',
            '&Dagger;': '‡',
            '&hellip;': '…',
            '&bull;': '•',
        };
        return htmlString.replace(/&[a-zA-Z0-9#]+;/g, (entity) => htmlEntitiesMap[entity] || entity);
    },
    replaceTagsValues(text, objeto, getMacroTag, valNotFind, notReplaceNotFind) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!text)
                return null;
            let newText = text;
            newText = this.replaceAll(newText, '@[', '');
            newText = this.replaceAll(newText, ']@', '');
            const replaceFunction = () => {
                const functionRegex = /#\[(function.*?)]#/g;
                newText = newText.replace(functionRegex, (_, functionContent) => {
                    let result = '';
                    try {
                        const decodedFunctionContent = this.decodeHtmlEntities(functionContent);
                        const func = new Function(`return ${decodedFunctionContent}`)();
                        if (typeof func === 'function') {
                            result = func();
                        }
                    }
                    catch (e) {
                        console.log(`Erro ao executar replace usando função (replaceTagsValues)!`, e);
                    }
                    return result;
                });
            };
            const replaceDollarSign = () => __awaiter(this, void 0, void 0, function* () {
                const functionRegex = /\$\[([^\]]+)\]\$/g;
                const matches = [...newText.matchAll(functionRegex)];
                const results = yield Promise.all(matches.map((match) => __awaiter(this, void 0, void 0, function* () {
                    const [fullMatch, tag] = match;
                    const param = tag.replace(/[<>]/g, '');
                    let result = param;
                    let isReplace = false;
                    if (getMacroTag) {
                        try {
                            const resultMacro = yield getMacroTag(param, objeto);
                            if (resultMacro) {
                                result = resultMacro;
                                isReplace = true;
                            }
                        }
                        catch (e) {
                            console.log('Erro ao executar macro!', e);
                        }
                    }
                    if (!isReplace) {
                        const valParam = objeto[param.toLowerCase()] || objeto[param];
                        if (valParam) {
                            result = String(valParam);
                        }
                        else {
                            result = notReplaceNotFind ? `$[${param}]$` : valNotFind || '';
                        }
                    }
                    return { fullMatch, result };
                })));
                results.forEach(({ fullMatch, result }) => {
                    newText = newText.replace(fullMatch, result);
                });
            });
            yield replaceDollarSign();
            replaceFunction();
            return newText;
        });
    },
};
